import React, { useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Container,
  Col
} from "reactstrap";
import PageHeader from "./PageHeader";

const faqs = [
  {
    q: "What to wear?",
    a:
      "Comfortable clothing is essential. You may need an extra layer for the relaxation session at the end of the class."
  },

  {
    q: "What equipment do I need?",
    a: "It's possible to perform classes without any equipment.  However, it can enhance the class to use therabands, weights and soft pilates balls.  These are available from me or I can advise on purchasing."
  },
  {
    q: "Is Pilates or these classes suitable for me?",
    a:
      "Every exercise is taught with varying levels of difficulty. This allows you to choose the level that best suits you, whether you’re a Pilates pro or inexperienced beginner."
  },
  {
    q: "If I can't make one class would I be able to switch to another one?",
    a:
      "In the event that you are unable to attend a class, I run a swap system across all sessions. If enough notice is given in advance, there is the option to swap for an alternative time or day."
  }
];

const FAQ = () => {
  const [showItem, show] = useState(-1);
  return (
    <div>
      <PageHeader name="Frequently Asked Questions" />
      {/* FAQ Questions */}
      <Container>
        {faqs.map((faq, idx) => (
          <Row key={idx} className="mb-1">
            <Col sm="12">
              <Card>
                <CardBody onClick={() => show(idx)}>
                  <h4 className="text-info">{faq.q}</h4>
                  {showItem === idx ? (
                    <h5 className="text-primary">{faq.a}</h5>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};
export default FAQ;
