import React from "react";
import { Container, CardImg, CardText, Card, CardBody } from "reactstrap";
import PageHeader from "./PageHeader";
import {pics, text} from "./Pictures/pics";

const Pictures = (props) => {
  return (
    <div>
      <PageHeader name="Pictures" />
      <Container >
        {pics.map((p, idx) => (
          <Card key={idx} className="mr-auto ml-auto text-center" style={{width:600,marginBottom:"50px"}}>
            <CardImg top width="100%" src={p}></CardImg>
            <CardBody>
              <CardText>{text[idx]}</CardText>
            </CardBody>
          </Card>
        ))}
      </Container>
    </div>
  );
};

export default Pictures;
