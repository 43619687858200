import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import PageHeader from "./PageHeader";
import profile from "./Images/profile.jpg";
import appi from "./Images/appi.jpg";
import hcpc from "./Images/hcpc.jpg";
import csp from "./Images/csp.jpg";

const AboutMe = () => {
  return (
    <div>
      <PageHeader name="About Me" />
      {/* Text about me */}
      <div style={{ padding: 15 }}>
        <Container>
          <Row>
            <Col xs="12" md="3">
              <img
                className="centeredImage"
                src={profile}
                alt="profile"
                width="100%"
                hspace="20"
                style={{ maxWidth: "250px", borderRadius: 5 }}
              ></img>
              <p style={{ textAlign: "center" }}>Ros Duncombe</p>
            </Col>
            <Col xs="12" md="9">
              <Card>
                <CardBody>
                  <p>
                    In 1988 I qualified as a Chartered Physiotherapist at Kings
                    College Hospital, London. I then practiced for 27 years in
                    the NHS and in 1994 I began to increasingly specialise in
                    musculoskeletal and women's health as an outpatient
                    Physiotherapist. My interest in muscle imbalance led me to
                    begin training in <h1 style={{ fontSize: "1em", display:"inline", fontWeight:"normal"}}>Pilates</h1> in 2005.
                  </p>
                </CardBody>
                <CardBody>
                  <p>
                    In 2007 I became a fully certified
                    <a
                      href="https://appihealthgroup.com/instructor-directory/902121/rosalind-duncombe-fully-certified/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      APPI Pilates Instructor.
                    </a>
                    Since then, I have been running <h2 style={{ fontSize: "1em", display:"inline", fontWeight:"normal"}}>Pilates</h2> classes as well as
                    providing private physiotherapy in Maidenhead. I regularly
                    attend courses and workshops to ensure my classes include
                    the latest evidence-based practice. Although the classes are
                    limited to 12 people, I also offer one-to-one sessions. I
                    recommend that new joiners firstly attend a one-to-one
                    session with me, so that I can fully understand any
                    individual needs and help ease them into the exercises.
                  </p>
                </CardBody>
                {/* Banners for Contact and Testimonials*/}
                <CardBody>
                  <Link to="/classes">Pilates Classes</Link>
                  <Link to="/physio">Physiotherapy</Link>
                  <Link to="/contact">Contact</Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* The Images at the bottom of the page.  ADD CARDS HERE AND LINKS TO WEBSITES**** */}
      <Container>
        <Row>
          <Col xs="12" md="4">
            <a
              href="https://appihealthgroup.com/about/about-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="centeredImage"
                src={appi}
                alt="APPI"
                width="200"
                height="200"
              ></img>
            </a>
          </Col>
          <Col xs="12" md="4">
            <a
              href="https://www.hcpc-uk.org/about-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="centeredImage"
                src={hcpc}
                alt="HCPC"
                width="200"
                height="200"
              ></img>
            </a>
          </Col>
          <Col xs="12" md="4">
            <a
              href="https://www.csp.org.uk/about-csp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="centeredImage"
                src={csp}
                alt="HCPC"
                width="120"
                height="200"
              ></img>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutMe;
