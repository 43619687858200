import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import Iframe from "react-iframe";
import PageHeader from "./PageHeader";

const Classes = props => {
  return (
    <div>
      <PageHeader name="Pilates Classes" />
      {/* Title/Paragraph */}
      <Container className="mb-2">
        <Card>
          <CardBody>
            Classes are booked in advance for each school term, which usually
            comprises of six sessions. In the event that you are unable to
            attend a class, I run a swap system across all sessions. If enough
            notice is given in advance, there is the option to swap for an
            alternative time or day. For further information on prices and how
            to sign up, please
            <Link to="/contact">get in touch</Link>with me directly.
          </CardBody>
        </Card>
      </Container>


      {/* Zoom */}
      <Container style={{ padding: 15 }}>
        <Row>
          {" "}
          <Col xs="12" md="8">
            <Card>
              <CardBody>
                <h3>Online</h3>
                <h5>Zoom</h5>
              </CardBody>
              <Row>
                <Col><CardBody>Monday</CardBody></Col>
                <Col><CardBody>10:00 - 11:00am</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>18:30 - 19:30pm</CardBody></Col>
              </Row>
              <Row>
                <Col><CardBody>Wednesday</CardBody></Col>
                <Col><CardBody>10:00 - 11:00am</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>20:00 - 21:00pm</CardBody></Col>
              </Row>
              <Row>
                <Col><CardBody>Friday</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>12:30 - 13:30pm</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Stubbings Church Hall */}
      <Container style={{ padding: 15 }}>
        <Row>
          {" "}
          <Col xs="12" md="8">
            <Card>
              <CardBody>
                <h3>In Person</h3>
                <h5>Stubbings Church - The Soltau Centre</h5>
              </CardBody>
              <Row>
                <Col><CardBody>Monday</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>13:30 - 14:30pm</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
              </Row>
              <Row>
                <Col><CardBody>Wednesday</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>18:30 - 19:30pm</CardBody></Col>
              </Row>
              <Row>
                <Col><CardBody>Friday</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
                <Col><CardBody>14:30 - 15:30pm</CardBody></Col>
                <Col><CardBody>-</CardBody></Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.030470726889!2d-0.7779967844641679!3d51.527443417058784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48768813ee45a3b3%3A0x2a598c37e90b30c5!2sSt%20James-The-Less%20Stubbings%20%26%20Soltau%20Centre!5e1!3m2!1sen!2suk!4v1582481289643!5m2!1sen!2suk"
              style={{ padding: 15 }}
              width="100%"
              height="100%"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Classes;
