import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink } from "reactstrap";

const Menu = () => {
  const [isOpen, toggle] = useState(false);

  return (
    <div>
      <Navbar color="primary" dark expand="md">
        <NavbarToggler onClick={() => toggle(!isOpen)} />
        <NavbarBrand tag={Link} to="/">
          <h2>maidenheadpilates.com</h2>
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar onClick={() => toggle(false)}>
            <NavLink tag={Link} to="/aboutme">
              About Me
            </NavLink>
            <NavLink tag={Link} to="/pilates">
              Pilates
            </NavLink>
            <NavLink tag={Link} to="/classes">
              Classes
            </NavLink>
            <NavLink tag={Link} to="/physio">
              Physio
            </NavLink>
            <NavLink tag={Link} to="/pictures">
              Pictures
            </NavLink>
            <NavLink tag={Link} to="/contact">
              Contact
            </NavLink>
            <NavLink tag={Link} to="/faq">
              FAQ
            </NavLink>
            <NavLink tag={Link} to="/testimonials">
              Testimonials
            </NavLink>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Menu;
