import p1 from "./p1.jpg";
import p2 from "./p2.jpg";
import p3 from "./p3.jpg";
import p4 from "./p4.jpg";
import p5 from "./p5.jpg";
import p6 from "./p6.jpg";
import p7 from "./p7.jpg";
import p8 from "./p8.jpg";
import p9 from "./p9.jpg";
import p10 from "./p10.jpg";
import p11 from "./p11.jpg";
import p12 from "./p12.jpg";
import p13 from "./p13.jpg";
import p14 from "./p14.jpg";
import p15 from "./p15.jpg";

const pics = [
  p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15
]

const text = [
"Relax time for Scruffy",
"This ball is heavy!",
"I can't lift two!!!",
"I'm your new instructor - this is the 3 leg balance",
"...and breathe",
"Frobo joining in the pretzel stretch",
"George demonstrating side-stretch",
"Samba preparing for cobra",
"Riley waiting to start the class",
"Relax time all round",
"Orion helping with cat-cow",
"...still helping",
"",
"",
""
]

export {pics, text}

