import React, { useEffect } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import Page404 from "./components/Page404.js";
import Menu from "./components/Menu.js";
import AboutMe from "./components/AboutMe.js";
import Contact from "./components/Contact.js";
import Testimonials from "./components/Testimonials.js";
import Physio from "./components/Physio.js";
import Pictures from "./components/Pictures.js";
import FAQ from "./components/FAQ.js";
import Pilates from "./components/Pilates.js";
import ClassesNew from "./components/ClassesNew.js";
import Zoom from "./components/Zoom.js";

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <main className="main-content col-lg-12 col-md-12 col-sm-12 p-0">
        <HashRouter>
          <Menu />
          <Routes>
            <Route path="/" exact element={<AboutMe/>}/>
            <Route path="/zoom" element={<Zoom/>}/>
            <Route path="/aboutme" element={<AboutMe/>}/>
            <Route path="/pilates" element={<Pilates/>}/>
            <Route path="/classes" element={<ClassesNew/>}/>
            <Route path="/physio" element={<Physio/>}/>
            <Route path="/pictures" element={<Pictures/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/faq" element={<FAQ/>}/>
            <Route path="/testimonials" element={<Testimonials/>}/>
            <Route element={<Page404/>}/>
          </Routes>
        </HashRouter>
      </main>
    </div>
  );
};

export default App;
