import React from "react";
import { Container, Card, CardBody } from "reactstrap";

const PageHeader = props => {
  return (
    <Container className="mb-3 mt-3">
      <Card inverse color="info" className="text-center">
        <CardBody>
          <h3>
            <p>{props.name}</p>
          </h3>
        </CardBody>
      </Card>
    </Container>
  );
};

export default PageHeader;
