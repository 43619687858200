import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PageHeader from "./PageHeader";
import csp from "./Images/csp.jpg";

const Physio = (props) => {
  return (
    <div>
      <PageHeader name="Physiotherapy" />
      {/* one-to-one Content */}
      {/* SL6 5DN Location */}
      <Container>
        <Row>
          <Col xs="12" md="3">
            <img className="centeredImage" src={csp} alt="HCPC" width="120" height="200"></img>
          </Col>
          <Col xs="12" md="9">
            <Card>
              <CardBody>
                Individual one-to-one sessions are available on a regular basis for both Pilates and
                Physiotherapy assessment and treatment.
              </CardBody>
              <CardBody>
                Following a full assessment of your posture and movement patterns, a tailored series
                of strengthening, stretching and mobilising exercises can be taught and given to you
                as a personal Pilates exercise programme.
              </CardBody>
              <CardBody>
                One-to-one appointments are particularly useful as they focus on your individual
                needs and can identify specific muscle and movement imbalances. These sessions can
                also act as a boost to supplement your weekly classes.
              </CardBody>
              <CardBody>
                <Link to="/contact">Contact</Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Physio;
