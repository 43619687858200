import React, { useState } from "react";
import { Navigate } from "react-router";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const Page404 = () => {
  const [done, setDone] = useState(false);

  if (!done) {
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={() => setDone(true)}>maidenheadpilates.com</ModalHeader>
        <ModalBody>Sorry something went wrong</ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => setDone(true)}>
            Home
          </Button>{" "}
        </ModalFooter>
      </Modal>
    );
  } else {
    return <Navigate to="./" />;
  }
};

export default Page404;
