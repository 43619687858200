import React from "react";
import {
  Container,
  Card,
  CardBody
} from "reactstrap";
import PageHeader from "./PageHeader";

const Testimonials = () => {
  return (
    <div>
      <PageHeader name="Testimonials" />
      {/* Testimonials 
      <div style={{ padding: 15 }}>*/}
      <Container>      
        <Card className="mb-2">
          <CardBody>
            Ros is a wonderful combination of years of physiotherapy experience and Pilates teaching. This combination has the magic of noticing what is good / needs work generally to keep the body functioning well but also specifically for things like scoliosis. 
            After years of general classes I now have specific exercises suited to allow my back to be ready and sustainable from working at a desk to walking with a pack across the alps. 
            Over a course of 1:1 sessions from assessing me from a physio perspective and now building up for my scoliosis I'm stronger and more balanced than I have been for years.
            <br />        
            Thank you Ros x
          </CardBody>
        </Card>
        <Card className="mb-2 text-info">
          <CardBody>
            Ros combines her professional Physiotherapist knowledge with her
            skill and care as a Pilates teacher to create wonderful classes
            which have had a significant and very positive impact on my recovery
            following injury during labour and childbirth. The structure of the
            classes allows for everyone to progress at their own pace, and for
            Ros to assist where necessary. Class members are truly varied - all
            ages, physical abilities and genders take part. With Ros' support I
            am able to move forwards with my recovery and my life. Thank you
            Ros. <br />
            Angela
          </CardBody>
        </Card>
        <Card className="mb-2 text-primary">
          <CardBody>
            Since joining Ros' class after a pelvic injury it has literally
            changed my life. I now have more flexibility, movement and energy
            which I thought would never be possible.
            <br />
            Rebecca
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardBody>
            I've been seeing Ros for a few months now as a post natal patient
            and can highly recommend her. Over the years I have seen a few
            Physio's and Osteopaths whom have taken me on an interesting journey
            of discoveries but none of them have come close to really
            understanding my body quite like Ros. She takes a holistic view of
            the entire body and not just an injury in isolation. Being a Pilates
            Instructor really compliments her physiotherapy and rehabilitation
            guidance. Her wealth of experience and passion for what she
            practices makes our sessions invaluable. She's amazing.
            <br />
            Elly
          </CardBody>
        </Card>
        <Card className="mb-2 text-info">
          <CardBody>
            Following major orthopaedic surgery, and after my NHS Physiotherapy
            had come to an end, I was referred to Ros. What good luck! Ros has
            been marvellously patient and knowledgeable, working with me and
            adapting exercises wherever necessary. She has slowly but surely
            helped me to a level of core strength and mobility that is pretty
            remarkable!
            <br />
            Kathy F
          </CardBody>
        </Card>
        <Card className="mb-2 text-primary">
          <CardBody>
            Ros' Pilates classes have been fantastic for me since the birth of
            my 3rd child. She has helped me so much and without her I wouldn't
            be how I am today - stronger, fitter and happier. My third baby's
            shoulders got stuck in my pelvis dung birth (shoulder dystocia) and
            as a result my pelvis and surrounding joints were wrecked. I could
            barely walk weeks and months after the birth, I couldn't lift my
            legs up to put socks on and I had pelvic pain most of the time
            walking. I used to be quite sporty so it was a shock and emotionally
            hard to be like this. I started Pilates with Ros 3 years ago and am
            still going to her classes. I go once a week and have been
            diligently going, determined to improve physically and maintain the
            improvement I have made. I am strengthening my core and am gradually
            getting stronger to do more physical exercise again - what a relief!
            It took a year until I noticed small differences and now I am doing
            what I used to do before the birth - a bit of running and tennis,
            skiing, walking up and down hills. To think I could barely walk to
            the gate after the birth! My pelvis still isn't perfect and I'm not
            sure whether it will ever be again but Ros has made a tremendous
            difference to me. She is caring, helpful and a brilliant Pilates
            teacher because she is also a Physio. Her knowledge has been
            invaluable and I can't recommend and thank her enough.
            <br />
            Melissa B
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardBody>
            I started Pilates classes with Ros in 2009 choosing her because she
            was a physiotherapist and my hips and back were painful after giving
            birth. I tried osteopathy and massages over the years and whilst
            they helped, they never resolved things fully. I cant believe now it
            took me till 2015 to see Ros for one-to-one physiotherapy and to
            finally get my pelvis sorted. Ros has a detective-like approach
            which makes sense of how different pain interlinks and in what order
            it needs addressing. Her specialist pelvic and sacroiliac joint
            knowledge is exceptional. Alongside this she is compassionate and
            realistic, giving me one doable exercise to focus on alongside my
            preschool twins! She is now sorting out my shoulder and I no longer
            feel so old and that I will get back to sports I enjoyed before
            kids.
            <br />
            Caroline Burr
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Testimonials;
