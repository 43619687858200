import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PageHeader from "./PageHeader";
import classes from "./Images/classes.jpg";

const Pilates = (props) => {
  return (
    <div>
      <PageHeader name="Pilates" />

      {/* Text about me */}
      <Container>
        <Row xs="12" md="3">
          <Col>
            <img
              className="centeredImage"
              src={classes}
              alt="Class"
              width="100%"
              hspace="20"
              style={{ maxWidth: "250px", borderRadius: 5 }}
            ></img>
          </Col>
          {/* Info About Pilates */}
          <Col xs="12" md="8">
            <Card>
              <CardBody>
                Pilates is a unique form of exercise developed by Joseph H. Pilates in the early
                twentieth century. It combines smooth, flowing movements with exercises for the
                different muscle groups, whilst maintaining a strong, centred posture, controlled
                breathing and a clarity of mental focus.
              </CardBody>
              <CardBody>
                "Pilates is designed to give you suppleness, natural grace and skill that will be
                unmistakably reflected in the way you walk, in the way you play, and in the way you
                work." - J. Pilates.
              </CardBody>
              <CardBody>
                By developing your core stability and strength you will notice improvements in your
                movement control, whilst reducing pressure on your joints and minimising the risk of
                injury.
              </CardBody>
              {/* Banners for Contact and Testimonials*/}
              <CardBody>
                <Link to="/classes">Classes</Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Pilates;
