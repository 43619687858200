import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PageHeader from "./PageHeader";

const Contact = props => {
  return (
    <div>
      <PageHeader name="Contact" />
      {/* Contact Details */}
      <Container>
        <Card>
          <CardBody className="h4">
            <Row>
              <Col sm="3" md="2">
                <p className="text-primary">mobile</p>
              </Col>
              <Col sm="9" md="10">
                <a href="tel:07733322865">07733 322865</a>
              </Col>
            </Row>
            <Row>
              <Col sm="3" md="2">
                <p className="text-primary">email</p>
              </Col>
              <Col sm="9" md="10">
                <a href="mailto:ros@maidenheadpilates.com?subject=Pilates%20web%20enquiry">
                  ros@maidenheadpilates.com
                </a>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
export default Contact;
