import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import zoom1 from "./Images/zoom1.jpeg";
import zoom2 from "./Images/zoom2.jpeg";
import zoom3 from "./Images/zoom3.jpeg";
import zoom4 from "./Images/zoom4.jpeg";
import zoom5 from "./Images/zoom5.jpeg";
import zoom6 from "./Images/zoom6.jpeg";
import zoom7 from "./Images/zoom7.jpeg";
import zoom8 from "./Images/zoom8.jpeg";
import windows1 from "./Images/windows1.jpeg";
import Winpic1 from "./Images/Winpic1.jpeg";
import Winpic2 from "./Images/Winpic2.jpeg";
import Winpic3 from "./Images/Winpic3.jpeg";
import Winpic4 from "./Images/Winpic4.jpeg";
import macos from "./Images/macos.jpeg";
import ios from "./Images/ios.jpeg";
import Android from "./Images/android.jpeg";

const Zoom = () => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <div>
      {/* Navigator for easy understanding */}
      <Breadcrumb>
        <BreadcrumbItem active>
          <Link to="./">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <Link to="./classes">Classes</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <Link to="./zoom">Zoom</Link>
        </BreadcrumbItem>
      </Breadcrumb>

      {/*TITLE PIECE*/}
      <Container>
        <Card inverse color="info" className="mb-2">
          <h3 className="text-center mt-2">Zoom Classes</h3>
          <CardBody>
            <p className="lead">
              Following the outbreak of COVID-19 and government advice I will be live streaming
              classes at the usual times. Instructions to help get set up on Zoom are on the tabs
              below. Please try to get Zoom installed in advance of the class and do let me know of
              any problems - I have helpers!
            </p>
          </CardBody>
        </Card>

        {/*Tab Nav for each app */}
        <Nav tabs>
          {/*About Zoom*/}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => setActiveTab("1")}
            >
              General
            </NavLink>
          </NavItem>
          {/*IOS*/}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => setActiveTab("2")}
            >
              iPhone
            </NavLink>
          </NavItem>
          {/*Android*/}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => setActiveTab("3")}
            >
              Android
            </NavLink>
          </NavItem>
          {/*MacOSX*/}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => setActiveTab("4")}
            >
              Mac
            </NavLink>
          </NavItem>
          {/*Windows*/}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => setActiveTab("5")}
            >
              Windows
            </NavLink>
          </NavItem>
        </Nav>

        {/*TAB PAGES INFO*/}
        <TabContent activeTab={activeTab}>
          {/*About*/}
          <TabPane tabId="1">
            <Row className="p-4">
              <Col>
                <Card className="mb-1">
                  <CardBody>
                    - Zoom will run on PCs (Mac or Windows), iPhones, iPads and Androids.
                  </CardBody>
                </Card>
                <Card className="mb-1">
                  <CardBody>
                    - There's no need to create a Zoom account or sign in to join the class.
                  </CardBody>
                </Card>
                <Card className="mb-1">
                  <CardBody>- I will send out separate Zoom class invites individually.</CardBody>
                </Card>
                <Card className="mb-1">
                  <CardBody>- I will be on video and audio.</CardBody>
                </Card>
                <Card className="mb-1">
                  <CardBody>
                    - Your own device will ideally be sending me video & audio although it's not
                    necessary to join the class.
                  </CardBody>
                </Card>
                <Card className="mb-1">
                  <CardBody>
                    - If you see the message 'Host has another meeting in Progress' please be
                    patient as I am probably still running a previous class.
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          {/*IOS*/}
          <TabPane tabId="2" className="instructionTab">
            <Row className="p-4">
              <Col>
                <img src={ios} alt="Logo" style={{ maxWidth: "80px" }} />
                <h4>Setting up an iPhone or iPad</h4>
              </Col>
            </Row>
            <ol>
              <li>
                <p>
                  Go to Zoom in the{" "}
                  <a
                    href="https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    App Store
                  </a>
                </p>
              </li>
              <li>Install Zoom</li>
              <li>
                In my text/email invite, click the link 'Join Zoom Meeting' which looks like e.g.
                https://zoom.us/j/556036997
              </li>
              <li>Then just wait for the class to start and you'll be joined automatically</li>
            </ol>
          </TabPane>

          {/*Android*/}
          <TabPane tabId="3" className="instructionTab">
            <Row className="p-4">
              <Col>
                <img src={Android} alt="Logo" style={{ maxWidth: "80px" }} />
                <h4>Setting up an Android</h4>
              </Col>
            </Row>
            <ol>
              <li>
                Go to Zoom in the{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Play Store
                </a>
              </li>
              <li>Install Zoom {/* Modal for doesn't work? */}</li>
              <li>
                In my text/email invite, click the link 'Join Zoom Meeting' which looks like e.g.
                https://zoom.us/j/556036997
              </li>
              <li>Then just wait for the class to start and you'll be joined automatically</li>
            </ol>
          </TabPane>

          {/*MacOSX*/}
          <TabPane tabId="4" className="instructionTab">
            <Row className="p-4">
              <Col>
                <img src={macos} alt="Logo" style={{ maxWidth: "80px" }} />
                <h4>Setting up a Mac</h4>
              </Col>
            </Row>
            <ol>
              <li>
                <p>
                  Click on the meeting invite I sent (looks like https://zoom.us/j/556036997) to
                  open your browser and download Zoom - click 'Allow'
                </p>
                <img src={zoom1} alt="Logo" />
              </li>
              <li>
                <p>Click on the down-arrow button and double-click 'Zoom.pkg' to install</p>
                <img src={zoom2} alt="Logo" />
              </li>
              <li>
                <p>Click 'Continue' to allow installation</p>
                <img src={zoom3} alt="Logo" />
              </li>
              <li>
                <p>
                  Click again on the meeting invite I sent (e.g. https://zoom.us/j/556036997) and
                  click 'Allow'
                </p>
                <img src={zoom4} alt="Logo" />
              </li>
              <li>
                <p>Zoom should now open and show the waiting page for the class start e.g.</p>
                <img src={zoom5} alt="Logo" />
              </li>
              <li>
                <p>
                  Click 'Test Computer Audio' to see if your microphone/speakers are working
                  properly
                </p>
              </li>
              <li>
                <p>If you see a message asking for microphone access, click 'Ok'</p>
                <img src={zoom6} alt="Logo" />
              </li>
              <li>
                <p>If you see a message asking for camera access, click 'Ok'</p>
                <img src={zoom7} alt="Logo" />
              </li>
              <li>
                <p>
                  If you see a message asking for downloads folder access, click either 'Ok' or
                  'Dont Allow' - it's not required
                </p>
                <img src={zoom8} alt="Logo" />
              </li>
            </ol>
          </TabPane>

          {/*Windows*/}
          <TabPane tabId="5" className="instructionTab">
            <Row className="p-4">
              <Col>
                <img src={windows1} alt="Logo" width="15%" />
                <h4>Setting up a Windows pc</h4>
              </Col>
            </Row>
            <ol>
              <li>
                <p>
                  Click on the meeting invite I sent (e.g. https://zoom.us/j/556036997) to open your
                  browser and download Zoom
                </p>
                <img src={Winpic1} alt="Logo" />
              </li>
              <li>
                <p>Open the download to install</p>
                <img src={Winpic2} alt="Logo" />
              </li>
              <li>
                <p>
                  Click again on the meeting invite link (e.g. https://zoom.us/j/556036997) and
                  allow Zoom to open the meeting
                </p>
                <img src={Winpic3} alt="Logo" />
              </li>
              <li>
                <p>Enter your name and join the meeting</p>
                <img src={Winpic4} alt="Logo" />
              </li>
            </ol>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
};

export default Zoom;
